<template>
    <el-dialog :title="dTitle" :visible.sync="visible" width="760px" close-on-click-modal show-close>
        <el-form :model="dataFrom" label-position="top" :rules="dataRules" ref="data-from" :disabled="titleType === 'details'">
            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item label="名称" prop="name">
                        <el-input type="text" v-model="dataFrom.name"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="节点编号" prop="code">
                        <el-input type="text" v-model="dataFrom.code"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item label="父节点" prop="parentCode">
                        <el-select v-model="dataFrom.parentCode">
                            <el-option v-for="item in dataList" :key="item.id" :label="item.name" :value="item.code"/>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="类型" prop="type">
                        <el-select v-model="dataFrom.type">
                            <el-option v-for="item in typeList" :value="item.id" :label="item.name" :key="item.id"/>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item label="备注" prop="remark">
                        <el-input type="textarea" v-model="dataFrom.remark"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="visible = false">取 消</el-button>
            <el-button type="primary" @click="saveOrUpdate">确 定</el-button>
        </span></el-dialog
    >
</template>
<script>
import {cloneDeep} from 'lodash';
//图纸数据模型
const dataFromIint = {
    id: '',
    code: '',
    parentCode: '',
    name: '',
    type: '',
    drawId: '',
    flag: '',
    remark: '',
    ranks: '',
};
export default {
    name: 'drawingAdd',
    data() {
        return {
            visible: false,
            dataFrom: {},
            dataRules: {
                code: [{required: true, message: '不能为空', trigger: 'blur'}],
                name: [{required: true, message: '不能为空', trigger: 'blur'}],
                type: [{required: true, message: '不能为空', trigger: 'blur'}],
            },
            titleType: '',
            dTitle: '',
            dataList: [],
            typeList: [],
        };
    },
    created(){
        this.gainListData();
        this.gainTypeByParentCode(new Array("Drawing_Tree_Type"));
    },
    methods: {
        show(data) {
            this.titleType = data.type;
            switch (data.type) {
                case 'ins':
                    this.dTitle = '添加树';
                    break;
                case 'details':
                    this.dTitle = '详情';
                    break;
                case 'upd':
                    this.dTitle = '修改';
                    break;
                default:
                    this.dTitle = '添加树';
                    break;
            }
            if (data.id){
                this.gainDataById(data.id);
            }else {
                 this.dataFrom = cloneDeep(dataFromIint);
            }
            this.visible = true;
           
        },
        async gainDataById(id){
            let res = await this.$client.gainReviewDrawTreeById(id);
            this.dataFrom = res.data;
        },
        async gainListData(){
            let res = await this.$client.gainReviewDrawList('');
            this.dataList = res.data;
        },
        async gainTypeByParentCode(code){
            let res = await this.$client.listDictionaryChildrenArr(code);
            this.typeList = res.data['Drawing_Tree_Type'];
        },
        saveOrUpdate() {
            let func;
            let param = cloneDeep(this.dataFrom);
            if (!this.dataFrom.id) {
                func = this.$client.saveReviewDrawTree;
                delete param['id'];
            } else {
                func = this.$client.updateReviewDrawTree;
            }
            this.$refs['data-from'].validate((vail) => {
                if (vail) {
                    func(param).then((res) => {
                        if (res.code == 0) {
                            this.$message({
                                type: 'success',
                                message: this.$t('操作成功！'),
                            });
                            this.$emit('getPowerGrid');
                            this.visible = false;
                        }
                    });
                }
            });
        },
    },
};
</script>
