<template>
    <div>
        <el-form :rules="dataRule" :model="dataForm" ref="dataForm">
            <!-- <el-form-item label="图纸名称" prop="name">
                <el-input v-model="dataForm.name" placeholder="图纸名称"></el-input>
            </el-form-item> -->
            <el-tabs v-model="activeName">
                <el-tab-pane label="单线图" name="1">
                    <p style="font-weight: bold">单线图：{{ dataForm.drawing10.length }}</p>
                    <div>
                        <div class="draw-item" v-for="(item, index) in dataForm.drawing10" :key="item.ossId">
                            <div style="position: relative">
                                <el-image
                                    @click="showPdf(item)"
                                    style="width: 300px; height: 200px"
                                    :src="uploadUrl + item.thumbUrl"
                                    fit="contain"
                                ></el-image>
                                <i
                                    class="el-icon-remove-outline"
                                    @click="handleRemove(dataForm.drawing10, index)"
                                    style="
                                        cursor: pointer;
                                        position: absolute;
                                        right: 10px;
                                        top: 5px;
                                        font-size: 24px;
                                        color: #e50000;
                                    "
                                ></i>
                            </div>
                            <div style="display: flex; flex-direction: column">
                                <el-input v-model="item.ossId" style="display: none"></el-input>
                                <el-form-item
                                    :prop="'drawing10.' + index + '.name'"
                                    :rules="{required: true, message: '图纸名称不能为空', trigger: 'blur'}"
                                >
                                    <el-input v-model="item.name" placeholder="输入图纸名称"></el-input>
                                </el-form-item>
                                <el-form-item
                                    :prop="'drawing10.' + index + '.num'"
                                    :rules="{required: true, message: '图纸编号不能为空', trigger: 'blur'}"
                                >
                                    <el-input v-model="item.num" placeholder="输入图纸编号"></el-input>
                                </el-form-item>
                                <el-form-item
                                    :prop="'drawing10.' + index + '.workNum'"
                                    :rules="{required: true, message: '工单号不能为空', trigger: 'blur'}"
                                >
                                    <el-input v-model="item.workNum" placeholder="输入工单号"></el-input>
                                </el-form-item>
                                <el-form-item
                                    :prop="'drawing10.' + index + '.make'"
                                    :rules="{required: true, message: '制作人不能为空', trigger: 'blur'}"
                                >
                                    <el-input v-model="item.make" placeholder="输入制作人"></el-input>
                                </el-form-item>
                                <el-form-item
                                    :prop="'drawing10.' + index + '.makeTime'"
                                    :rules="{required: true, message: '制作时间不能为空', trigger: 'blur'}"
                                >
                                    <el-date-picker
                                        style="width: 100%"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                        v-model="item.makeTime"
                                        type="datetime"
                                        placeholder="选择日期时间"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </div>
                        </div>
                        <el-upload
                            :headers="uploadHeaders"
                            :show-file-list="false"
                            :action="uploadUrl"
                            drag
                            class="draw-item"
                            multiple
                            accept="application/pdf"
                            :before-upload="beforeUpload"
                            :on-success="uploadCallback1"
                            :auto-upload="true"
                        >
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                        </el-upload>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="环网柜主线图" name="2">
                    <p style="font-weight: bold">环网柜主线图：{{ dataForm.drawing20.length }}</p>
                    <div>
                        <div class="draw-item" v-for="(item, index) in dataForm.drawing20" :key="item.ossId">
                            <div style="position: relative">
                                <el-image
                                    @click="showPdf(item)"
                                    style="width: 300px; height: 200px"
                                    :src="uploadUrl + item.thumbUrl"
                                    fit="contain"
                                ></el-image>
                                <i
                                    class="el-icon-remove-outline"
                                    @click="handleRemove(dataForm.drawing20, index)"
                                    style="
                                        cursor: pointer;
                                        position: absolute;
                                        right: 10px;
                                        top: 5px;
                                        font-size: 24px;
                                        color: #e50000;
                                    "
                                ></i>
                            </div>
                            <div style="display: flex; flex-direction: column">
                                <el-input v-model="item.ossId" style="display: none"></el-input>
                                <el-form-item
                                    :prop="'drawing20.' + index + '.name'"
                                    :rules="{required: true, message: '图纸名称不能为空', trigger: 'blur'}"
                                >
                                    <el-input v-model="item.name" placeholder="输入图纸名称"></el-input>
                                </el-form-item>
                                <el-form-item
                                    :prop="'drawing20.' + index + '.num'"
                                    :rules="{required: true, message: '图纸编号不能为空', trigger: 'blur'}"
                                >
                                    <el-input v-model="item.num" placeholder="输入图纸编号"></el-input>
                                </el-form-item>
                                <el-form-item
                                    :prop="'drawing20.' + index + '.workNum'"
                                    :rules="{required: true, message: '工单号不能为空', trigger: 'blur'}"
                                >
                                    <el-input v-model="item.workNum" placeholder="输入工单号"></el-input>
                                </el-form-item>
                                <el-form-item
                                    :prop="'drawing20.' + index + '.make'"
                                    :rules="{required: true, message: '制作人不能为空', trigger: 'blur'}"
                                >
                                    <el-input v-model="item.make" placeholder="输入制作人"></el-input>
                                </el-form-item>
                                <el-form-item
                                    :prop="'drawing20.' + index + '.makeTime'"
                                    :rules="{required: true, message: '制作人不能为空', trigger: 'blur'}"
                                >
                                    <el-date-picker
                                        style="width: 100%"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                        v-model="item.makeTime"
                                        type="datetime"
                                        placeholder="选择日期时间"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </div>
                        </div>
                        <el-upload
                            :headers="uploadHeaders"
                            :show-file-list="false"
                            :action="uploadUrl"
                            drag
                            class="draw-item"
                            multiple
                            accept="application/pdf"
                            :before-upload="beforeUpload"
                            :on-success="uploadCallback2"
                            :auto-upload="true"
                        >
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                        </el-upload>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="箱式变电站主线图" name="3">
                    <p style="font-weight: bold">箱式变电站主线图：{{ dataForm.drawing30.length }}</p>
                    <div>
                        <div class="draw-item" v-for="(item, index) in dataForm.drawing30" :key="item.ossId">
                            <div style="position: relative">
                                <el-image
                                    @click="showPdf(item)"
                                    style="width: 300px; height: 200px"
                                    :src="uploadUrl + item.thumbUrl"
                                    fit="contain"
                                ></el-image>
                                <i
                                    class="el-icon-remove-outline"
                                    @click="handleRemove(dataForm.drawing30, index)"
                                    style="
                                        cursor: pointer;
                                        position: absolute;
                                        right: 10px;
                                        top: 5px;
                                        font-size: 24px;
                                        color: #e50000;
                                    "
                                ></i>
                            </div>
                            <div style="display: flex; flex-direction: column">
                                <el-input v-model="item.ossId" style="display: none"></el-input>
                                <!-- <el-form-item
                                    :prop="'drawing30.' + index + '.drawingName'"
                                    :rules="{required: true, message: '图纸名称不能为空', trigger: 'blur'}"
                                >
                                    <el-input v-model="item.drawingName" placeholder="输入图纸名称"></el-input>
                                </el-form-item>
                                <el-form-item
                                    :prop="'drawing30.' + index + '.drawingCode'"
                                    :rules="{required: true, message: '图纸编号不能为空', trigger: 'blur'}"
                                >
                                    <el-input v-model="item.drawingCode" placeholder="输入图纸编号"></el-input>
                                </el-form-item>
                                <el-form-item
                                    :prop="'drawing30.' + index + '.drawingCode'"
                                    :rules="{required: true, message: '图纸编号不能为空', trigger: 'blur'}"
                                >
                                    <el-input v-model="item.drawingCode" placeholder="输入图纸编号"></el-input>
                                </el-form-item> -->
                                <el-form-item
                                    :prop="'drawing30.' + index + '.name'"
                                    :rules="{required: true, message: '图纸名称不能为空', trigger: 'blur'}"
                                >
                                    <el-input v-model="item.name" placeholder="输入图纸名称"></el-input>
                                </el-form-item>
                                <el-form-item
                                    :prop="'drawing30.' + index + '.num'"
                                    :rules="{required: true, message: '图纸编号不能为空', trigger: 'blur'}"
                                >
                                    <el-input v-model="item.num" placeholder="输入图纸编号"></el-input>
                                </el-form-item>
                                <el-form-item
                                    :prop="'drawing30.' + index + '.workNum'"
                                    :rules="{required: true, message: '工单号不能为空', trigger: 'blur'}"
                                >
                                    <el-input v-model="item.workNum" placeholder="输入工单号"></el-input>
                                </el-form-item>
                                <el-form-item
                                    :prop="'drawing30.' + index + '.make'"
                                    :rules="{required: true, message: '制作人不能为空', trigger: 'blur'}"
                                >
                                    <el-input v-model="item.make" placeholder="输入制作人"></el-input>
                                </el-form-item>
                                <el-form-item
                                    :prop="'drawing30.' + index + '.makeTime'"
                                    :rules="{required: true, message: '制作时间不能为空', trigger: 'blur'}"
                                >
                                    <el-date-picker
                                        style="width: 100%"
                                        v-model="item.makeTime"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                        type="datetime"
                                        placeholder="选择日期时间"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </div>
                        </div>
                        <el-upload
                            :headers="uploadHeaders"
                            :show-file-list="false"
                            :action="uploadUrl"
                            drag
                            class="draw-item"
                            multiple
                            accept="application/pdf"
                            :before-upload="beforeUpload"
                            :on-success="uploadCallback3"
                            :auto-upload="true"
                        >
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                        </el-upload>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </el-form>
        <div class="submit-btn-group">
            <el-button @click="closeForm">关闭</el-button>
            <el-button @click="saveApply('0')" type="primary" v-if="!dataForm.isRestart">保存</el-button>
            <el-button @click="submitApply('1')" type="primary" v-if="!dataForm.isRestart">保存并关联</el-button>
            <!-- <el-button @click="restartDrawingApply()" type="primary" ref="btn" v-if="dataForm.isRestart">修改单线图</el-button> -->
        </div>
        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
    </div>
</template>

<script>
import {previewPdf} from '@/utils';
import {mapGetters} from 'vuex';
const elType = ['10', '20', '30']; // 单线，环网，变电站
export default {
    props: {
        treeId: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            uploadHeaders: {
                token: this.$store.state.token,
            },
            activeName: '1',
            uploadUrl: this.axios.defaults.baseURL + '/files',
            baseServer: this.axios.defaults.baseURL,
            dataForm: {
                id: '',
                name: '',
                type: '',
                make: '',
                makeTime: '',
                num: '',
                workNum: '',
                url: '',
                stat: '',
                reviewUser: '',
                reviewTime: '',
                istandId: '',
                isUse: '',
                becomeTime: '',
                becomeUser: '',
                uploadTime: '',
                uploadTser: '',
                drawing10: [],
                drawing20: [],
                drawing30: [],
            },
            dialogImageUrl: '',
            dialogVisible: false,
            disabled: false,
            dataRule: {
                name: [{required: true, message: '图纸名称不能为空', trigger: 'blur'}],
                num: [{required: true, message: '图纸编号不能为空', trigger: 'blur'}],
            },
            uploadLoading: null,
        };
    },
    computed: {
        ...mapGetters(['userInfo']),
    },
    methods: {
        beforeUpload(file) {
            const isPDF = file.type.indexOf('pdf') > -1;
            const isLt2M = file.size / 1024 / 1024 < 20;
            if (!isPDF) {
                this.$message.error('只能上传PDF格式文件!');
                return false;
            }
            if (!isLt2M) {
                this.$message.error('文件大小不能超过20MB!');
                return false;
            }
            this.uploadLoading = this.$loading({
                lock: true,
                text: '请稍候',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
            });
        },
        handleRemove(obj, index) {
            obj.splice(index, 1);
        },
        showPdf(item) {
            previewPdf(
                this.baseServer + '/files/' + item.ossId + '?token=' + this.uploadHeaders.token,
                item.drawingName + '  ' + item.drawingCode
            );
        },
        handelResData(res) {
            const data = res.data;
            data.thumbUrl = '/' + data.id + '/thumbnail?token=' + this.uploadHeaders.token;
            const item = {
                ossId: data.id,
                drawingName: '',
                drawingCode: '',
            };
            Object.assign(item, data);
            return item;
        },
        handelResData2(list) {
            for (let item of list) {
                item.thumbUrl = '/' + item.ossId + '/thumbnail?token=' + this.uploadHeaders.token;
            }
        },
        uploadCallback1(res) {
            this.uploadLoading.close();
            let res1 = this.handelResData(res);
            res1['imageName'] = res1.name;
            delete res1['name'];
            res1['imageType'] = res1.type;
            delete res1['type'];
            this.dataForm.drawing10.push({
                name: '',
                type: elType[0],
                make: '',
                makeTime: '',
                num: '',
                workNum: '',
                url: res1.thumbnailPath,
                istandId: res1.id,
                uploadTime: res1.createTime,
                uploadUser: this.userInfo.username,
                ...res1,
            });
        },
        uploadCallback2(res) {
            this.uploadLoading.close();
            let res1 = this.handelResData(res);
            res1['imageName'] = res1.name;
            delete res1['name'];
            res1['imageType'] = res1.type;
            delete res1['type'];
            this.dataForm.drawing20.push({
                name: '',
                type: elType[1],
                make: '',
                makeTime: '',
                num: '',
                workNum: '',
                url: res1.thumbnailPath,
                istandId: res1.id,
                uploadTime: res1.createTime,
                uploadUser: this.userInfo.username,
                ...res1,
            });
        },
        uploadCallback3(res) {
            this.uploadLoading.close();
            let res1 = this.handelResData(res);
            res1['imageName'] = res1.name;
            delete res1['name'];
            res1['imageType'] = res1.type;
            delete res1['type'];
            this.dataForm.drawing30.push({
                name: '',
                type: elType[2],
                make: '',
                makeTime: '',
                num: '',
                workNum: '',
                url: res1.thumbnailPath,
                istandId: res1.id,
                uploadTime: res1.createTime,
                uploadUser: this.userInfo.username,
                ...res1,
            });
        },
        // saveApply(formState) {
        //     this.$refs.dataForm.validate((valid) => {
        //         if (valid) {
        //             const data = this.getParam();
        //             data.formState = formState ? formState : '0';
        //             this.$client.saveOrSubmitDrawingApply(data).then(() => {
        //                 this.$message({
        //                     message: '操作成功',
        //                     type: 'success',
        //                     duration: 1500,
        //                 });
        //                 this.closeForm(1);
        //             });
        //         }
        //     });
        // },
        saveApply(formState) {
            this.$refs.dataForm.validate((valid) => {
                if (valid) {
                    const data = this.getParam();
                    data.formState = formState ? formState : '0';
                    data.params = [...data.drawing10, ...data.drawing20, ...data.drawing30];
                    if (formState === '1' && data.params.length > 1) {
                        this.$message({
                            message: '只能关联其中类型的一张图。',
                            type: 'warning',
                            duration: 1500,
                        });
                        return false;
                    }
                    data.treeId = this.treeId;
                    this.$client.saveReviewDrawBatch(data).then(() => {
                        this.$message({
                            message: '操作成功',
                            type: 'success',
                            duration: 1500,
                        });
                        this.closeForm(1);
                    });
                }
            });
        },
        restartDrawingApply() {
            this.$refs.dataForm.validate((valid) => {
                if (valid) {
                    const data = this.getParam();
                    this.$client.restartDrawingApply(data).then(() => {
                        this.$message({
                            message: '操作成功',
                            type: 'success',
                            duration: 1500,
                        });
                        this.closeForm(1);
                    });
                }
            });
        },
        getParam() {
            const data = Object.assign({}, this.dataForm);
            data.drawing10 = this.dataForm.drawing10;
            data.drawing20 = this.dataForm.drawing20;
            data.drawing30 = this.dataForm.drawing30;
            data.restartActive = this.activeName;
            if (this.dataForm.isRestart) {
                switch (this.activeName) {
                    case '1':
                        data.drawing20 = [];
                        data.drawing30 = [];
                        break;
                    case '2':
                        data.drawing10 = [];
                        data.drawing30 = [];
                        break;
                    case '3':
                        data.drawing10 = [];
                        data.drawing20 = [];
                        break;
                }
            }
            return data;
        },
        submitApply() {
            this.$confirm('确定绑定到该设备吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                if (this.treeId) {
                    let selNode = this.$parent.$parent.$parent.$refs['apply-tree'].$refs.tree.getCurrentNode();
                    if (selNode && selNode.drawId) {
                        this.$message({
                            message: '选择的树节点已经绑定图纸',
                            type: 'warning',
                            duration: 1500,
                        });
                        return false;
                    } else {
                        this.saveApply('1');
                    }
                } else {
                    this.$message({
                        message: '请先选择设备树节点',
                        type: 'warning',
                        duration: 1500,
                    });
                    return false;
                }
            });
        },
        setBtnName(isRestart, activeName) {
            let name = '保存并发布';
            if (isRestart) {
                switch (activeName) {
                    case '1':
                        name = '修改单线图';
                        break;
                    case '2':
                        name = '修改环网柜主线图';
                        break;
                    case '3':
                        name = '修改箱式变电站主线图';
                        break;
                }
            }
            // this.$refs.btn.$el.innerText = name;
        },
        init(row) {
            this.dataForm.id = row.businessFormId || 0;
            this.dataForm.isRestart = row.isRestart;
            this.$nextTick(() => {
                // if (this.dataForm.id) {
                //     this.$client.getDrawingApplyInfo(this.dataForm.id).then((res) => {
                //         Object.assign(this.dataForm, res.drawingApply);
                //         this.dataForm.workflowId = row.id;
                //         this.dataForm.isRestart = row.isRestart;
                //         this.$client.getDrawingList({applyId: this.dataForm.id}).then((data) => {
                //             this.handelResData2(data.drawing.drawing10);
                //             this.handelResData2(data.drawing.drawing20);
                //             this.handelResData2(data.drawing.drawing30);
                //             this.dataForm.drawing10 = data.drawing.drawing10;
                //             this.dataForm.drawing20 = data.drawing.drawing20;
                //             this.dataForm.drawing30 = data.drawing.drawing30;
                //         });
                //     });
                // }
                this.setBtnName(this.dataForm.isRestart, this.activeName);
            });
        },
        closeForm(type) {
            this.$emit('closeForm', type);
        },
    },
    watch: {
        activeName: function (val) {
            this.setBtnName(this.dataForm.isRestart, val);
        },
    },
};
</script>
<style>
.draw-item {
    display: inline-block;
    width: 300px;
    padding: 0 10px;
    margin-bottom: 20px;
}

.submit-btn-group {
    text-align: center;
}
</style>
