<template>
    <div class="monitor-wrap">
        <div class="monitor-wrap__left">
            <drawing-apply-tree ref="apply-tree" @node-click="handleNodeClick"></drawing-apply-tree>
        </div>
        <div class="monitor-wrap__right">
            <drawing-list :gridId="gridId" v-if="listFresh"></drawing-list>
        </div>
    </div>
</template>

<script>
import DrawingApplyTree from './drawingApplyTree';
import DrawingList from './drawingList';
export default {
    components: {
        DrawingApplyTree,
        DrawingList,
    },
    data() {
        return {
            gridId: null,
            listFresh: true,
        };
    },
    methods: {
        handleNodeClick(data) {
            this.gridId = this._.cloneDeep(data).id;
            //刷新列表组件
            this.listFresh = false;
            this.$nextTick(() => {
                this.listFresh = true;
            });
        },
    },
    created() {},
};
</script>

<style lang="less" scoped>
    .monitor-wrap {
        height: 100%;
        display: flex;
        &__left {
            height: 100%;
        }
        &__right {
            flex-grow: 1;
            position: relative;
            height: 100%;
            overflow: hidden;
            display: flex;
            flex-direction: column;
        }
    }
</style>
