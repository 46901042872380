<template>
    <div>
        <el-tabs v-model="activeName">
            <el-tab-pane label="单线图" name="1" v-if="dataForm.type === '10'">
                <p style="font-weight: bold">
                    图纸名称：{{ dataForm.name }} 工作单号：{{ dataForm.workNum }} 单线图：{{ drawing10.length }}
                </p>
                <el-form :inline="true">
                    <el-form-item v-for="(item, index) in drawing10" :key="index">
                        <el-image
                            @click="showPdf(item)"
                            style="width: 300px; height: 200px"
                            :src="baseServer + item.istandId + '/thumbnail?token=' + $store.state.token"
                            fit="contain"
                        ></el-image>
                        <div style="display: flex; flex-direction: column">
                            <span>图纸名称：{{ item.name }}</span>
                            <span>图纸编号：{{ item.num }}</span>
                            <span>制图人<em v-html="'&nbsp;&nbsp;&nbsp;'"></em>：{{ item.make }}</span>
                            <span>制图时间：{{ item.makeTime }}</span>
                            <span v-if="item.stat">审核人<em v-html="'&nbsp;&nbsp;&nbsp;'"></em>：{{ item.reveiwUser }}</span>
                            <span v-if="item.stat">审核时间：{{ item.reveiwTime }}</span>
                            <span v-if="item.isUse">废除人<em v-html="'&nbsp;&nbsp;&nbsp;'"></em>：{{ item.becomeUser }}</span>
                            <span v-if="item.isUse">废除时间：{{ item.becomeTime }}</span>
                        </div>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
            <el-tab-pane label="户外开关站主线图" name="2" v-if="dataForm.type === '20'">
                <p style="font-weight: bold">
                    图纸名称：{{ dataForm.name }} 工作单号：{{ dataForm.workNum }} 环网柜主线图：：{{
                        drawing20.length
                    }}
                </p>
                <el-form :inline="true">
                    <el-form-item v-for="(item, index) in drawing20" :key="index">
                        <el-image
                            @click="showPdf(item)"
                            style="width: 300px; height: 200px"
                            :src="baseServer + item.istandId + '/thumbnail?token=' + $store.state.token"
                            fit="contain"
                        ></el-image>
                        <div style="display: flex; flex-direction: column">
                            <span>图纸名称：{{ item.name }}</span>
                            <span>图纸编号：{{ item.num }}</span>
                            <span>制图人<em v-html="'&nbsp;&nbsp;&nbsp;'"></em>：{{ item.make }}</span>
                            <span>制图时间：{{ item.makeTime }}</span>
                            <span v-if="item.stat">审核人<em v-html="'&nbsp;&nbsp;&nbsp;'"></em>：{{ item.reveiwUser }}</span>
                            <span v-if="item.stat">审核时间：{{ item.reveiwTime }}</span>
                            <span v-if="item.isUse">废除人<em v-html="'&nbsp;&nbsp;&nbsp;'"></em>：{{ item.becomeUser }}</span>
                            <span v-if="item.isUse">废除时间：{{ item.becomeTime }}</span>
                        </div>
                    </el-form-item>
                </el-form>
                <!-- <div v-if="drawing20 && drawing20.length">
                    <p>制图人：{{ reviewInfo.info10.createBy }}</p>
                    <p>制图时间：{{ reviewInfo.info10.createTime }}</p>
                    <p>校核人：{{reviewInfo.info20.checkName}}</p>
                    <p>校核意见：{{reviewInfo.info20.checkReview}}</p>
                    <p>校核时间：{{reviewInfo.info20.checkTime}}</p>
                    <p>审核人：{{ reviewInfo.info20.examineName }}</p>
                    <p>审核意见：{{ reviewInfo.info20.examineReview }}</p>
                    <p>审核时间：{{ reviewInfo.info20.examineTime }}</p>
                    <p>批准人：{{reviewInfo.info20.approvalName}}</p>
                    <p>批准意见：{{reviewInfo.info20.approvalReview}}</p>
                    <p>批准时间：{{reviewInfo.info20.approvalTime}}</p>
                </div> -->
            </el-tab-pane>
            <el-tab-pane label="站内主接线图" name="3" v-if="dataForm.type === '30'">
                <p style="font-weight: bold">
                    图纸名称：{{ dataForm.name }} 工作单号：{{ dataForm.workNum }} 箱式变电站主线图：{{
                        drawing30.length
                    }}
                </p>
                <el-form :inline="true">
                    <el-form-item v-for="(item, index) in drawing30" :key="index">
                        <el-image
                            @click="showPdf(item)"
                            style="width: 300px; height: 200px"
                            :src="baseServer + item.istandId + '/thumbnail?token=' + $store.state.token"
                            fit="contain"
                        ></el-image>
                        <div style="display: flex; flex-direction: column">
                            <span>图纸名称：{{ item.name }}</span>
                            <span>图纸编号：{{ item.num }}</span>
                            <span>制图人<em v-html="'&nbsp;&nbsp;&nbsp;'"></em>：{{ item.make }}</span>
                            <span>制图时间：{{ item.makeTime }}</span>
                            <span v-if="item.stat">审核人<em v-html="'&nbsp;&nbsp;&nbsp;'"></em>：{{ item.reveiwUser }}</span>
                            <span v-if="item.stat">审核时间：{{ item.reveiwTime }}</span>
                            <span v-if="item.isUse">废除人<em v-html="'&nbsp;&nbsp;&nbsp;'"></em>：{{ item.becomeUser }}</span>
                            <span v-if="item.isUse">废除时间：{{ item.becomeTime }}</span>
                        </div>
                    </el-form-item>
                </el-form>
                <!-- <div v-if="drawing30 && drawing30.length">
                    <p>制图人：{{ reviewInfo.info10.createBy }}</p>
                    <p>制图时间：{{ reviewInfo.info10.createTime }}</p>
                    <p>校核人：{{reviewInfo.info30.checkName}}</p>
                    <p>校核意见：{{reviewInfo.info30.checkReview}}</p>
                    <p>校核时间：{{reviewInfo.info30.checkTime}}</p>
                    <p>审核人：{{ reviewInfo.info30.examineName }}</p>
                    <p>审核意见：{{ reviewInfo.info30.examineReview }}</p>
                    <p>审核时间：{{ reviewInfo.info30.examineTime }}</p>
                    <p>批准人：{{reviewInfo.info30.approvalName}}</p>
                    <p>批准意见：{{reviewInfo.info30.approvalReview}}</p>
                    <p>批准时间：{{reviewInfo.info30.approvalTime}}</p>
                </div> -->
            </el-tab-pane>
            <!--<el-tab-pane label="发布信息" name="4">
                <el-form ref="dataForm2">
                    <el-form-item label="制图人：" style="margin-bottom: 0;">
                        <span>{{ dataForm.createBy }}</span>
                    </el-form-item>
                    <el-form-item label="制图时间：" style="margin-bottom: 0;">
                        <span>{{ dataForm.createTime }}</span>
                    </el-form-item>
                    <el-form-item label="校核人：" style="margin-bottom: 0;">
                        <span>{{ dataForm.review1 }}</span>
                    </el-form-item>
                    <el-form-item label="校核意见：" style="margin-bottom: 0;">
                        <span>{{ dataForm.reviewNote1 }}</span>
                    </el-form-item>
                    <el-form-item label="校核时间：" style="margin-bottom: 0;">
                        <span>{{ dataForm.reviewTime1 }}</span>
                    </el-form-item>
                    <el-form-item label="审核人：" style="margin-bottom: 0;">
                        <span>{{ dataForm.review2 }}</span>
                    </el-form-item>
                    <el-form-item label="审核意见：" style="margin-bottom: 0;">
                        <span>{{ dataForm.reviewNote2 }}</span>
                    </el-form-item>
                    <el-form-item label="审核时间：" style="margin-bottom: 0;">
                        <span>{{ dataForm.reviewTime2 }}</span>
                    </el-form-item>
                    <el-form-item label="批准人：" style="margin-bottom: 0;">
                        <span>{{ dataForm.review3 }}</span>
                    </el-form-item>
                    <el-form-item label="批准意见：" style="margin-bottom: 0;">
                        <span>{{ dataForm.reviewNote3 }}</span>
                    </el-form-item>
                    <el-form-item label="批准时间：" style="margin-bottom: 0;">
                        <span>{{ dataForm.reviewTime3 }}</span>
                    </el-form-item>
                </el-form>
            </el-tab-pane>-->
        </el-tabs>
    </div>
</template>

<script>
import {previewPdf} from '@/utils';
import {ConsoleLogger} from '@microsoft/signalr/dist/esm/Utils';

export default {
    props: {
        row: {
            type: Object,
            default: null,
        },
        type: {
            type: String,
            default: 'add',
        },
    },
    data() {
        return {
            activeName: '1',
            baseServer: this.axios.defaults.baseURL + '/files/',
            dataForm1: {
                id: 0,
                businessNumber: '',
                drawingName: '',
                drawingNum: '',
                drawingStatus: '',
                note: '',
            },
            dataForm: {
                id: '',
                name: '',
                type: '',
                make: '',
                makeTime: '',
                num: '',
                workNum: '',
                url: '',
                stat: '',
                reviewUser: '',
                reviewTime: '',
                istandId: '',
                isUse: '',
                becomeTime: '',
                becomeUser: '',
                uploadTime: '',
                uploadTser: '',
            },
            drawing10: [],
            drawing20: [],
            drawing30: [],
            reviewInfo: {
                //审核信息
                info10: {},
                info20: [],
                info30: {},
            },
        };
    },

    created() {},
    methods: {
        init(id) {
            this.dataForm.id = id;
            this.$nextTick(() => {
                // if (this.dataForm.id) {
                //     this.$client.getDrawingApplyInfo(this.dataForm.id).then(data => {
                //         this.dataForm = data.drawingApply;
                //     });
                if (this.dataForm.id) {
                    this.$client.ReviewDrawInfoById(this.dataForm.id).then((data) => {
                        this.dataForm = data.data;
                        let type = data.data.type;
                        switch (String(type)) {
                            case '10':
                                this.drawing10.push(data.data);
                                this.activeName = '1';
                                break;
                            case '20':
                                this.drawing20.push(data.data);
                                this.activeName = '2';
                                break;
                            case '30':
                                this.drawing30.push(data.data);
                                this.activeName = '3';
                                break;
                        }
                    });

                    // this.$client.getListFromDrawAndCopy(this.dataForm.istandId).then(data => {
                    //     this.drawing10 = data.drawing.drawing10;
                    //     this.drawing20 = data.drawing.drawing20;
                    //     this.drawing30 = data.drawing.drawing30;
                    //     this.setReviewInfo();
                    // });
                }
            });
        },
        setReviewInfo() {
            if (this.drawing10 && this.drawing10.length) {
                this.reviewInfo.info10 = this.drawing10[0];
            }
            if (this.drawing20 && this.drawing20.length) {
                this.reviewInfo.info20 = this.drawing20[0];
            }
            if (this.drawing30 && this.drawing30.length) {
                this.reviewInfo.info30 = this.drawing30[0];
            }
        },
        showPdf(item) {
            previewPdf(
                this.baseServer + item.istandId + '?token=' + this.$store.state.token,
                item.name + '  ' + item.num
            );
        },
        closeForm() {
            this.$emit('closeForm');
        },
    },
};
</script>
