export default {
    functional: true,
    props: {
        width: {
            type: Number,
            default: 300
        }
    },
    render(createElement, context) {
        let width = context.props.width;
        const data = {
            props: {
                mode: 'out-in'
            },
            on: {
                beforeEnter(el) {},

                enter(el) {
                    el.style.width = 0 + 'px';
                    el.style.padding = 0;
                },

                afterEnter(el) {
                    el.style.width = width + 'px';
                    el.style.padding = '';
                },

                beforeLeave(el) {
                    el.style.width = 0 + 'px';
                    el.style.padding = 0;
                },

                leave(el) {}
            }
        };
        return createElement('transition', data, context.children);
    }
};
