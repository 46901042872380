<template>
    <div class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100">
        <div class="ui-layout-content--full ui-form-actions" style="margin: 20px 20px 0px 20px">
            <el-button @click="create" v-if="createButton()" type="primary" style="border-radius: 4px"
                >+上传图纸</el-button
            >
            <el-button type="primary" style="border-radius: 4px" @click="isBecome('del')" v-if="deleteButton()"
                ><el-icon class="el-icon-delete"></el-icon>删除</el-button
            >
            <el-button type="primary" style="border-radius: 4px" @click="isBecome('rev')" v-if="auditButton()"
                ><el-icon class="el-icon-edit"></el-icon>审核</el-button
            >
            <el-button type="primary" style="border-radius: 4px; display: none" @click="isRefence"
                ><el-icon class="el-icon-setting"></el-icon>关联</el-button
            >
        </div>
        <div class="ui-layout-content--full ui-layout-flex--1" style="margin: 0px 20px 20px 20px">
            <ex-search-table-pagination
                ref="searchTable"
                :paramsMethod="searchParams"
                :fetch="$client.pageReviewDrawBatch"
                :columns="columns"
                :formOptions="formOptions"
                :searchWhenSortChange="true"
                @selection-change="selectionChangeHandle"
            >
                <template slot="append">
                    <el-table-column align="center" label="操作" type="action" width="120" fixed="right">
                        <template slot-scope="scope">
                            <el-tooltip content="查看" placement="top" :open-delay="200">
                                <div
                                    class="search-table-icon search-table-icon_detail"
                                    @click="detailHandle(scope.row)"
                                    type="text"
                                ></div>
                            </el-tooltip>
                            <!-- <el-tooltip content="下载" placement="top" :open-delay="200">
                                <div
                                        class="search-table-icon search-table-icon_download"
                                        @click="handleClick('down',scope.row.id)"
                                        type="text"
                                ></div>
                            </el-tooltip>
                            <el-tooltip content="编辑" placement="top" :open-delay="200">
                                <div
                                        class="search-table-icon search-table-icon_edit"
                                        @click="handleEdit(scope.row)"
                                        type="text"
                                ></div>
                            </el-tooltip>
                            <el-tooltip content="删除" placement="top" :open-delay="200">
                                <div
                                        class="search-table-icon search-table-icon_delete"
                                        @click="handleClick('del',scope.row.id)"
                                        type="text"
                                ></div>
                            </el-tooltip> -->
                        </template>
                    </el-table-column>
                </template>
            </ex-search-table-pagination>
        </div>
        <div class="ui-layout-content--full">
            <!-- 弹窗, 新增 / 修改 -->
            <el-dialog
                v-bind="readDialog"
                width="1050px"
                :title="readDialog.title"
                v-if="readDialog.visible"
                :visible.sync="readDialog.visible"
                :modal-append-to-body="true"
                :close-on-click-modal="false"
                :append-to-body="true"
                :show-close="true"
            >
                <create
                    ref="form_drawingApply"
                    :id="readDialog.currentId"
                    :treeId="readDialog.treeId"
                    :type="readDialog.type"
                    @closeForm="closeForm"
                ></create>
            </el-dialog>

            <el-dialog
                v-bind="readDialog2"
                width="1050px"
                :title="readDialog2.title"
                v-if="readDialog2.visible"
                :visible.sync="readDialog2.visible"
                :modal-append-to-body="true"
                :close-on-click-modal="false"
                :append-to-body="true"
                :show-close="true"
            >
                <detail ref="ref_detail" @closeForm="closeForm"></detail>
                <div style="width: 100%; text-align: center; margin-top: 50px">
                    <el-button @click="closeForm">关闭</el-button>
                </div>
            </el-dialog>
            <el-dialog
                v-bind="refenceDialog"
                width="560px"
                title="添加关联"
                v-if="refenceDialog.visible"
                :visible.sync="refenceDialog.visible"
                :modal-append-to-body="true"
                :close-on-click-modal="false"
                :append-to-body="true"
                :show-close="true"
            >
                <el-form ref="refence-from-dialog" :model="refenceData">
                    <el-row :gutter="24">
                        <el-col :span="16">
                            <el-form-item
                                prop="drawTreeId"
                                :rules="{required: true, message: '树节点不能为空', trigger: 'blur'}"
                            >
                                <el-select placeholder="选择树节点" v-model="refenceData.drawTreeId">
                                    <el-option
                                        v-for="item in drawTreeList"
                                        :label="item.name"
                                        :value="item.id"
                                        :key="item.id"
                                    />
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="refenceDialog.visible = false">取 消</el-button>
                    <el-button type="primary" @click="refenceDrawTree">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import Detail from './drawingApply-detail';
import Create from './create';
import {cloneDeep} from 'lodash';
import {mapGetters} from 'vuex';
export default {
    props: ['gridId'],
    data() {
        return {
            dataListSelections: [],
            readDialog: {
                visible: false,
                title: '',
                currentId: '',
                treeId: '',
                type: '',
            },
            readDialog2: {
                visible: false,
                title: '',
                currentId: '',
                type: '',
                row: null,
            },
            refenceDialog: {
                visible: false,
                titile: '添加关联',
            },
            gridName: '', // 下拉框双向绑定变量
            drawTreeList: [],
            drawTreeNodeData: {},
            refenceData: {
                drawTreeId: '',
            },
        };
    },
    components: {
        Detail,
        Create,
    },
    computed: {
        columns() {
            return [
                {
                    prop: 'name',
                    label: '图纸名称',
                },
                {
                    prop: 'stat',
                    label: '状态',
                    formatter: (row, column, cellValue) => {
                        return cellValue ? '审核通过' : '待审核';
                    },
                },
                // {
                //     prop: 'use',
                //     label: '是否废除',
                //     formatter: (row, column, cellValue) => {
                //         return cellValue ? '已废除' : '未废除';
                //     },
                // },
                {
                    prop: 'num',
                    label: '图纸编号',
                },
                {
                    prop: 'workNum',
                    label: '图纸工单号',
                },
                {
                    prop: 'uploadTime',
                    label: '上传时间',
                },
            ];
        },
        formOptions() {
            return {
                resetBtnCallback: () => {
                    this.gridId = '';
                    this.gridName = '';
                },
                forms: [
                    {
                        prop: 'gridId',
                        slotName: 'gridForm',
                    },
                    {
                        prop: 'key',
                        itemType: 'input',
                        clearable: true,
                        placeholder: '请输入图纸名称',
                    },
                ],
            };
        },
        ...mapGetters(['userInfo']),
    },
    methods: {
        searchParams(params) {
            params.gridId = this.gridId || '';
            return params;
        },
        closeForm(type) {
            this.readDialog.visible = false;
            this.readDialog2.visible = false;
            if (type) {
                this.$refs.searchTable.searchHandler();
            }
        },
        // 多选
        selectionChangeHandle(val) {
            this.dataListSelections = val;
        },
        isRefence() {
            this.refenceDialog.visible = true;
            this.gainListData();
        },
        async gainListData() {
            let res = await this.$client.gainReviewDrawList('notExistsDrawID');
            this.drawTreeList = res.data;
        },
        isBecome(valType) {
            let param = cloneDeep(this.dataListSelections);
            let isOpe = true,
                func = null,
                filterParam;
            let arg = {data: [], ope: ''};
            if (this.dataListSelections.length <= 0) {
                this.$message({
                    message: '未选择图纸',
                    type: 'warning',
                    duration: 1500,
                });
                return false;
            }
            if (valType === 'del') {
                if (!this.deleteButton()) {
                    return;
                }
                func = this.$client.deleteDrawInfoBatch;
                isOpe = false;
            } else if (valType === 'bec') {
                func = this.$client.updReviewDrawInfoBatch;
                filterParam = param.filter((i) => !(i.use || i.isUse));
                filterParam.forEach((item) => {
                    item.isUse = 1;
                    item.use = 1;
                    item.becomeTime = this.$dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss');
                    item.becomeUser = this.userInfo.username;
                });
                arg['ope'] = 'bem';
            } else if (valType === 'rev') {
                if (!this.auditButton()) {
                    return;
                }
                func = this.$client.updReviewDrawInfoBatch;
                filterParam = param.filter((i) => !i.stat);
                filterParam.forEach((item) => {
                    item.stat = 1;
                    item.reviewTime = this.$dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss');
                    item.reviewUser = this.userInfo.username;
                });
            }
            arg.data = filterParam || [];
            // debugger;
            if (isOpe) {
                func(arg).then((res) => {
                    if (res.code === 0) {
                        this.$message({
                            type: 'success',
                            message: '操作成功',
                            duration: 1500,
                        });
                    }
                    this.$nextTick(() => {
                        this.$refs.searchTable.searchHandler();
                    });
                });
            } else {
                this.$confirm('是否删除该图纸, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                })
                    .then(() => {
                        let id = param.map((item) => item.id)[0];
                        func(id).then((res) => {
                            if (res.code === 0) {
                                this.$message({
                                    type: 'success',
                                    message: '删除成功!',
                                });
                            }
                            this.$nextTick(() => {
                                this.$refs.searchTable.searchHandler();
                            });
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消删除',
                        });
                    });
            }
        },
        // 新增
        create() {
            if (this.createButton()) {
                this.readDialog = {
                    title: '上传图纸',
                    currentId: 0,
                    treeId: this.gridId,
                    visible: true,
                    type: 'add',
                };
            } else {
                this.$message({
                    type: 'warning',
                    message: '你没权限上传',
                });
            }
        },
        refenceDrawTree() {
            if (this.dataListSelections.length <= 0) {
                this.$message({
                    message: '未选择图纸',
                    type: 'warning',
                    duration: 1500,
                });
                return false;
            }
            this.$refs['refence-from-dialog'].validate((vai) => {
                if (vai) {
                    this.$client
                        .updateReviewDrawRefenceTree(this.refenceData.drawTreeId, this.dataListSelections[0].id)
                        .then((res) => {
                            if (res.code === 0) {
                                this.$message({
                                    message: '操作成功',
                                    type: 'success',
                                    duration: 1500,
                                });
                            }
                            this.refenceDialog.visible = false;
                            this.$refs.searchTable.searchHandler();
                        });
                }
            });
        },
        //  修改
        handleEdit(row) {
            let item = row;
            //是否重新开始流程
            let isRestart = 0;
            if (item.drawingStatus !== '0' && item.drawingStatus !== 'C' && item.drawingStatus !== 'D') {
                if (item.drawingStatus === 'S') {
                    //流程结束才能修改
                    isRestart = 1;
                } else {
                    this.$message({
                        type: 'warning',
                        message: this.$t('图纸已提交，不能操作！'),
                    });
                    return;
                }
            }
            this.readDialog = {
                title: '上传图纸',
                currentId: item.id,
                visible: true,
                type: 'edit',
            };
            this.$nextTick(() => {
                this.$refs.form_drawingApply.init({businessFormId: item.id, id: item.workflowId, isRestart: isRestart});
            });
        },
        handleClick(key, id) {
            switch (key) {
                case 'down':
                    this.downHandle(id);
                    break;
                case 'del':
                    this.deleteHandle(id);
                    break;
                default:
            }
        },

        // 下载
        downHandle(id) {
            if (id) {
                const ids = [];
                ids.push(id);
                if (ids.length === 0) {
                    this.$message.error('请选择图纸！');
                    return;
                }
                this.$confirm('确定进行下载操作?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    location.href =
                        this.axios.defaults.baseURL +
                        '/business/drawingApply/download?ids=' +
                        ids.join(',') +
                        '&token=' +
                        this.$store.state.token;
                });
            }
        },
        // 删除
        deleteHandle(id) {
            if (id) {
                const ids = [];
                ids.push(id);
                this.$confirm('确定进行删除操作?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    this.$client.delDrawingApply(ids).then(() => {
                        this.$message({
                            message: '操作成功',
                            type: 'success',
                            duration: 1500,
                        });
                        this.$refs.searchTable.searchHandler();
                    });
                });
            }
        },
        // 详情
        detailHandle(row) {
            this.readDialog2 = {
                title: row.name,
                currentId: row.id,
                visible: true,
                type: 'detail',
                row,
            };
            this.$nextTick(() => {
                this.$refs.ref_detail.init(row.id);
            });
        },
        createButton() {
            if (this.$store.state.userInfo.roleCodeList.includes('BianDianZhanRole')) {
                return true;
            }
            return false;
        },
        deleteButton() {
            return this.auditButton();
        },
        auditButton() {
            if (this.$store.state.userInfo.roleCodeList.includes('FJB')) {
                return true;
            }
            return false;
        },
    },
};
</script>
