<template>
    <div class="tree-panel__wrap">
        <el-menu-collapse-transition>
            <div class="tree-panel horizontal-collapse-transition" v-show="!collapse">
                <el-input placeholder="请输入线路名称" v-model="filterText"></el-input>
                <el-button @click="showAdd('ins')" type="primary">+ 新增</el-button>
                <!-- <el-button @click="isDelNode('del')" type="primary"
                    ><el-icon class="el-icon-delete"></el-icon>删除</el-button
                >
                <el-button @click="showAdd('upd')" type="primary"
                    ><el-icon class="el-icon-edit-outline"></el-icon>修改</el-button
                > -->
                <!-- <el-button @click="showAdd('details')" type="primary"
                    ><el-icon class="el-icon-view"></el-icon>详情</el-button
                > -->
                <el-scrollbar style="height: calc(100% - 58px)">
                    <el-tree
                        class="filter-tree"
                        :expand-on-click-node="false"
                        :data="treeData"
                        :props="defaultProps"
                        default-expand-all
                        :filter-node-method="filterNode"
                        :highlight-current="true"
                        node-key="id"
                        ref="tree"
                    >
                        <span class="custom-tree-node" slot-scope="{node, data}">
                            <div
                                class="tree-item tree-text"
                                @contextmenu="showContextMenu(data, $event)"
                                @click="closeContextmeun(data)"
                            >
                                {{ data.name }}
                            </div>
                        </span>
                    </el-tree>
                </el-scrollbar>
                <!-- 查看图纸 -->
                <!-- <el-dialog
                    width="900px"
                    :title="downDialog.title"
                    v-if="downDialog.visible"
                    :visible.sync="downDialog.visible"
                    :modal-append-to-body="true"
                    :close-on-click-modal="true"
                    :append-to-body="true"
                    :show-close="true"
                >
                    <device-drawing-list
                        :pic="downDialog.pic"
                        @closeForm="downDialog.visible = false"
                    ></device-drawing-list>
                </el-dialog> -->
                <v-contextmenu ref="tableCellContextmenu">
                    <!-- <v-contextmenu-item @click="handleContextAction('detail')">
                        <i class="el-icon-s-tools icon-margin-left"></i>编辑线路
                    </v-contextmenu-item> -->
                    <v-contextmenu-item @click="handleContextAction('delete')">
                        <i class="el-icon-delete-solid icon-margin-left"></i>删除
                    </v-contextmenu-item>
                </v-contextmenu>
            </div>
        </el-menu-collapse-transition>
        <div class="verticality-collapse-trigger" @click="handleToggleCollpase">
            <i :class="collapse ? 'el-icon-arrow-right' : 'el-icon-arrow-left'"></i>
        </div>
        <drawing-tree-add ref="drawing_add" @getPowerGrid="getPowerGrid" />
    </div>
</template>

<script>
import {genItems, pickItemByProp} from '@/utils/formItemUtil';
import {addClass, removeClass, hasClass} from 'element-ui/src/utils/dom';
import ElMenuCollapseTransition from '../powerGridMonitor/ElMenuCollapseTransition.js';
import drawingTreeAdd from './drawingTreeAdd.vue';

let netObj = genItems([
    {
        prop: 'name',
        itemType: 'input',
        label: '线路名称',
        rules: [
            {
                required: true,
            },
        ],
    },
    {
        prop: 'drawingApplyId',
        itemType: 'select',
        label: '图纸ID',
        rules: [
            {
                required: true,
            },
        ],
    },
]);
export default {
    components: {
        ElMenuCollapseTransition,
        drawingTreeAdd,
    },
    watch: {
        filterText(val) {
            this.$refs.tree.filter(val);
        },
    },
    data() {
        let {items: netItems, values: netValues, rules: netRules} = netObj;
        return {
            collapse: false,
            netItems,
            netValues,
            netRules,
            filterText: '',
            treeData: [],
            defaultProps: {
                children: 'children',
                label: 'label',
            },
        };
    },
    methods: {
        handleToggleCollpase() {
            this.collapse = !this.collapse;
        },
        showContextMenu(data, e) {
            e.preventDefault();
            this._contextItemData = data;
            this.$refs.tableCellContextmenu.show({
                left: e.clientX,
                top: e.clientY,
            });
        },
        closeContextmeun(data) {
            this.$emit('node-click', data);
            this.$refs.tableCellContextmenu.hide();
        },
        filterNode(value, data) {
            if (!value) {
                return true;
            }
            return (
                data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
                data.type.toLowerCase().indexOf(value.toLowerCase()) !== -1
            );
        },
        showAdd(type) {
            let item = {type: type};
            let node = this.$refs.tree.getCurrentNode();

            if (!node && type !== 'ins') {
                this.$message({
                    type: 'warning',
                    message: this.$t('未选择节点'),
                });
                return false;
            }

            if (type !== 'ins' && node) {
                item['id'] = node.id;
            }
            this.$refs.drawing_add.show(item);
        },
        isDelNode() {
            let node = this.$refs.tree.getCurrentNode();
            if (!node) {
                this.$message({
                    type: 'warning',
                    message: this.$t('未选择节点'),
                });
            } else if (node.children.length > 0) {
                this.$message({
                    type: 'warning',
                    message: this.$t('有子节点，不可删除'),
                });
                return false;
            } else if (node.id) {
                this.$client.delReviewDrawTreeById(node.id).then((res) => {
                    if (res.code === 0) {
                        this.$message({
                            type: 'success',
                            message: this.$t('操作成功'),
                        });
                        this.$nextTick(() => {
                            this.getPowerGrid();
                        });
                    }
                });
            }
        },
        validate(form) {
            return new Promise((resolve) => {
                if (!form) {
                    resolve(true);
                    return;
                }
                form.validate((valid) => {
                    if (valid) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                });
            });
        },
        getPowerGrid() {
            this.treeData = [];
            this.$client.gainReviewDrawTree().then((res) => {
                //设置根节点和未匹配节点
                let gen = {
                    name: '所有',
                    type: 'all',
                    children: [],
                };
                //未匹配节点
                // let notMatchNode = {
                //     id: 'isNotMatch',
                //     name: '未关联图纸',
                //     type: 'isNotAll',
                // };
                // gen.children.push(notMatchNode);
                let treeData = res.data;
                if (treeData) {
                    let firstItem = treeData;
                    if (firstItem) {
                        gen.children.push(...firstItem);
                    }
                }
                this.treeData.push(gen);
                this.$nextTick(() => {
                    this.$refs.tree.setCurrentKey(gen.id);
                    this.$emit('node-click', gen);
                });
            });
        },
        handleContextAction(action) {
            let data = this._contextItemData;
            // debugger;
            if (action === 'delete') {
                if (data.hasChildGrid && data.children && data.children.length) {
                    this.$message({
                        message: '请先删除此线路的所有子线路，再删除此线路',
                        type: 'error',
                    });
                } else {
                    this.$confirm('确认删除 [ ' + data.name + ' ]线路？', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                    })
                        .then(() => {
                            this.$nextTick(() => {
                                this.doRemoveTreeNode(data);
                            });
                        })
                        .catch(() => {
                            this.$message({
                                type: 'info',
                                message: '已取消删除',
                            });
                        });
                }
            }
        },
        doRemoveTreeNode(data) {
            this.$client.delReviewDrawTreeById(data.id).then((res) => {
                this.getPowerGrid();
            });
        },
    },
    created() {
        this.getPowerGrid();
        // 获取图纸id下拉选项
        this.$client.ReviewDrawInfolist().then((res) => {
            let selectInput = pickItemByProp(this.netItems, 'drawingApplyId');
            let option = res.data.map((it) => {
                return {
                    key: it.name,
                    value: it.id,
                };
            });
            // 设置图纸ip选项options
            if (selectInput) {
                this.$set(selectInput.options, 'option', option);
            }
        });
    },
};
</script>

<style lang="less" scoped>
.tree-panel {
    height: calc(100% - 28px);
    padding: 10px;
    width: 300px;
    // /deep/ .el-input__inner {
    //     background-color: #fff;
    //     border-radius: 4px;
    //     border: 1px solid #adadad;
    //     color: #606266;
    // }
    // /deep/ .el-input__inner::placeholder {
    //     color: #c0c4cc;
    // }
    /deep/ .filter-tree {
        height: calc(100% - 58px);
    }

    // /deep/ .el-tree {
    //     color: #606266;
    // }
    &_hide {
        display: none;
    }

    &__wrap {
        height: 100%;
        position: relative;
    }

    .custom-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        padding-right: 8px;
    }
}

.tooltip-icon i {
    margin-left: 5px;
    cursor: pointer;
}

// /deep/ .custom-tree-node {
//     height: 26px;
// }
/deep/ .tree-item {
    flex-grow: 1;
    height: 26px;
    line-height: 26px;
    user-select: none;
}

.root-add-box {
    font-size: 14px;
    // color: #606266;
    height: 30px;
    line-height: 30px;
    cursor: pointer;

    &:hover {
        color: #486dd5;
    }
}

.icon-margin-left {
    margin-right: 5px;
}
.el-button {
    border-radius: 4px;
    margin-top: 1.8em;
    margin-bottom: 0.8em;
    width: 22%;
    .el-icon [class|='el-icon-'] {
        margin-right: 2%;
    }
}
</style>
